import {
  UserOutlined,
  ProjectOutlined,
  SolutionOutlined,
  SafetyCertificateOutlined,
  CameraOutlined,
  InsertRowBelowOutlined,
  RadiusUprightOutlined,
  ContainerOutlined,
  DesktopOutlined,
  OneToOneOutlined,
  InsuranceOutlined,
  PlaySquareOutlined,
  ApartmentOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { lazy } from 'react'
import { ResourceMap } from '../utils/consts'

export const getManageCenterRoutes = (
  manageCenterResource: ResourceMap['manageCenter'],
  userInfo: UserInfo
) => {
  const manageCenterRoutes: Routes = [
    {
      path: '/manageCenter',
      name: '管理中心',
      resource: manageCenterResource.index,
      component: require('../app/ManageLayout').default,
      routes: [
        {
          name: '数据看板',
          icon: <LineChartOutlined />,
          resource: manageCenterResource.manageStatistics,
          path: '/manageCenter/statisticalData',
          component: lazy(() => import('../pages/manageCenter/statisticalData')),
        },
        {
          path: '/manageCenter/authManage',
          name: '权限管理',
          icon: <SafetyCertificateOutlined />,
          resource: manageCenterResource.authManage,
          routes: [
            {
              name: '用户管理',
              resource: manageCenterResource.userManage,
              path: '/manageCenter/authManage/userManage',
              component: lazy(() => import('../pages/manageCenter/authManage/userManage')),
            },
            {
              name: '角色管理',
              resource: manageCenterResource.roleManage,
              path: '/manageCenter/authManage/roleManage',
              component: lazy(() => import('../pages/manageCenter/authManage/roleManage')),
            },
          ],
        },
        {
          name: '企业管理',
          icon: <SolutionOutlined />,
          resource: manageCenterResource.enterpriseManageList,
          path: '/manageCenter/enterprise',
          routes: [
            {
              name: '企业管理',
              resource: manageCenterResource.enterpriseManageList,
              path: '/manageCenter/enterprise/enterpriseManage',
              component: lazy(() => import('../pages/manageCenter/enterprise/enterpriseManage')),
            },
            {
              name: '考核配置',
              resource: manageCenterResource.examineConfig,
              path: '/manageCenter/enterprise/examineConfig',
              component: lazy(() => import('../pages/manageCenter/enterprise/examineConfig')),
            },
            {
              name: '企业考核总览',
              resource: manageCenterResource.examineOverview,
              path: '/manageCenter/enterprise/examineOverview',
              component: lazy(() => import('../pages/manageCenter/enterprise/examineOverview')),
            },
          ],
        },
        {
          name: '管理员管理',
          icon: <UserOutlined />,
          resource: manageCenterResource.administratorManage,
          path: '/manageCenter/administratorManage',
          component: lazy(() => import('../pages/manageCenter/administratorManage')),
        },
        {
          name: '实体管理',
          icon: <ProjectOutlined />,
          resource: manageCenterResource.entityManage,
          path: '/manageCenter/entityManage',
          component: lazy(() => import('../pages/manageCenter/entityManage')),
        },
        {
          name: '车辆管理',
          icon: <InsertRowBelowOutlined />,
          resource: manageCenterResource.bikeManage,
          path: '/manageCenter/bikeManage',
          routes: [
            {
              name: '车辆备案管理',
              resource: manageCenterResource.bikeRecordManage,
              path: '/manageCenter/bikeManage/recordManage',
              component: lazy(() => import('../pages/manageCenter/bikeManage/recordManage')),
            },
            {
              name: '车辆定位上报管理',
              resource: manageCenterResource.bikeManageReportPositionMenu,
              path: '/manageCenter/bikeManage/bikeReportPositionManage',
              component: lazy(
                () => import('../pages/manageCenter/bikeManage/bikeReportPositionManage')
              ),
            },
            {
              name: '车辆核验信息',
              resource: manageCenterResource.verificationList,
              path: '/manageCenter/bikeManage/verificationManage',
              component: lazy(() => import('../pages/manageCenter/bikeManage/verificationManage')),
            },
            {
              name: '违规告警',
              resource: manageCenterResource.violationAlarmList,
              path: '/manageCenter/bikeManage/violationAlarm',
              component: lazy(() => import('../pages/manageCenter/bikeManage/violationAlarm')),
            },
          ],
        },
        {
          name: '任务中心',
          icon: <DesktopOutlined />,
          resource: manageCenterResource.taskCenterList,
          path: '/manageCenter/taskCenter',
          component: lazy(() => import('../pages/manageCenter/taskCenter')),
        },
        {
          name: '围栏管理',
          icon: <RadiusUprightOutlined />,
          resource: manageCenterResource.fenceManageList,
          path: '/manageCenter/fenceManage',
          routes: [
            {
              name: '全部围栏',
              resource: manageCenterResource.fenceManageAllFence,
              path: '/manageCenter/fenceManage/allFences',
              component: lazy(() => import('../pages/manageCenter/fenceManage/allFences')),
            },
            {
              name: '围栏淤积统计',
              resource: manageCenterResource.fenceManageDeposition,
              path: '/manageCenter/fenceManage/depositStatistic',
              component: lazy(() => import('../pages/manageCenter/fenceManage/depositStatistic')),
            },
            {
              name: '秩序重点区域',
              resource: manageCenterResource.fenceManageOrderKeyRegion,
              path: '/manageCenter/fenceManage/orderKeyRegion',
              component: lazy(() => import('../pages/manageCenter/fenceManage/orderKeyRegion')),
            },
          ],
        },
        {
          name: '运维信息',
          icon: <DesktopOutlined />,
          resource: manageCenterResource.operationManage,
          path: '/manageCenter/operationManage',
          component: lazy(() => import('../pages/manageCenter/operationManage')),
        },
        {
          name: '拍照识别溯源',
          icon: <CameraOutlined />,
          resource: manageCenterResource.photographMangeMenu,
          path: '/manageCenter/photographMange',
          component: lazy(() => import('../pages/manageCenter/photographManage')),
        },
        {
          name: '订单管理',
          icon: <ContainerOutlined />,
          resource: manageCenterResource.orderManage,
          path: '/manageCenter/orderManage',
          component: lazy(() => import('../pages/manageCenter/orderManage')),
        },
        {
          name: '蓝牙嗅探',
          icon: <OneToOneOutlined />,
          resource: manageCenterResource.bluetoothSniff,
          path: '/manageCenter/bluetoothSniffList',
          component: lazy(() => import('../pages/manageCenter/bluetoothSniff')),
        },
        {
          name: '秩序管理',
          icon: <SolutionOutlined />,
          resource: manageCenterResource.orderlyManage,
          path: '/manageCenter/orderlyManage',
          routes: [
            {
              name: '重点区域表现',
              resource: manageCenterResource.keyRegionPerform,
              path: '/manageCenter/orderlyManage/keyRegionPerform',
              component: lazy(() => import('../pages/manageCenter/orderlyManage/keyRegionPerform')),
            },
            {
              name: '智能巡检明细',
              resource: manageCenterResource.keyOrderlyDetail,
              path: '/manageCenter/orderlyManage/intelligentInspection',
              component: lazy(
                () => import('../pages/manageCenter/orderlyManage/intelligentInspection')
              ),
            },
          ],
        },
        {
          name: '调查取证',
          icon: <InsuranceOutlined />,
          resource: manageCenterResource.inquiryEvidence,
          path: '/manageCenter/inquiryEvidence',
          component: lazy(() => import('../pages/manageCenter/inquiryEvidence')),
        },
        {
          path: '/manageCenter/nationalStandardVideo',
          name: '摄像头管理',
          resource: manageCenterResource.cameraManage,
          icon: <PlaySquareOutlined />,
          routes: [
            {
              path: '/manageCenter/nationalStandardVideo/deviceManage',
              name: '通道管理',
              resource: manageCenterResource.cameraChannelManage,
              component: lazy(
                () => import('../pages/manageCenter/nationalStandardVideo/deviceManage')
              ),
            },
            {
              path: '/manageCenter/nationalStandardVideo/serviceConfig',
              name: '服务配置',
              resource: manageCenterResource.cameraConfigManage,
              component: lazy(
                () => import('../pages/manageCenter/nationalStandardVideo/serviceConfig')
              ),
            },
          ],
        },
        {
          name: 'API管理',
          icon: <ApartmentOutlined />,
          resource: manageCenterResource.apiManagement,
          path: '/manageCenter/apiConfigManage',
          component: lazy(() => import('../pages/manageCenter/apiConfigManage')),
        },
        {
          name: '数据开放平台',
          icon: <ProjectOutlined />,
          resource: manageCenterResource.manageOpenapi,
          path: '/manageCenter/openApiManage',
          routes: [
            {
              path: '/manageCenter/openApiManage/config',
              name: '数据开放API配置',
              resource: manageCenterResource.manageOpenapiConfig,
              component: lazy(() => import('../pages/manageCenter/openApiManage/config/index')),
            },
          ],
        },
        { path: '*', component: require('../pages/notFound').default },
      ],
    },
  ]
  return manageCenterRoutes
}
