import React, { useState, useEffect, useRef, useMemo, Key } from 'react'
import { Form, Dropdown, Tooltip } from 'antd'
import { DownOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useHistory, Link } from 'react-router-dom'
import classnames from 'classnames'
import qs from 'qs'

import {
  useUserResources,
  useAppSelector,
  useAppDispatch,
  useDataCenterSearchParams,
  useTheme,
} from '@/utils/hooks'
import {
  currentTime,
  selectBizFromBizType,
  transformArea,
  addFirstChildLatLng,
  getAllParentIds,
  findTreeNode,
  lsSetItem,
  isMobile,
} from '@/utils/util'
import { APP } from '@/redux/actionTypes'
import { AreaTreeSelect, AdminEntitySelect, BizSelect, SearchSelect } from '@/components'
import request from '@/utils/request'
import { currentArea, currentBiz, currentFence } from '@/redux/reducers/app'
import {
  businessLineMap,
  defaultMapBackgroundColor,
  defaultSubjectColor,
  districtLevelMap,
} from '@/utils/consts'
import { layerControlIcon } from '@/pages/dataCenter/center/map/consts'
import { setTheme } from '@/utils/theme'

import type { SearchSelectRef } from '@/components/select/SearchSelect'

import './Header.less'

const pathMap = {
  dataCenter: { name: '数据中心', url: '/dataCenter' },
  manageCenter: { name: '管理中心', url: '/manageCenter' },
  subwayCenter: { name: '地铁中心', url: '/subwayCenter' },
  patrolCenter: { name: '巡检中心', url: '/patrolCenter' },
}

const dataIcon = require('_root/assets/img/data-icon.png')
const hierarchyFillIcon = require('_root/assets/img/hierarchy-fill.png')
const titleBarImg = require('_root/assets/img/title-bar-img.png')

const Header: React.FC = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const [app] = useAppSelector(state => [state.app])
  const { userInfo, currentEntity: curEntity, currentArea: curArea, currentBiz: curBiz } = app
  const [manageCenterRs, patrolCenterRs, subwayCenterRs, dataCenterRs] = useUserResources(
    resources => [
      resources.manageCenter,
      resources.patrolCenter,
      resources.subwayCenter,
      resources.dataCenter,
    ]
  )

  const [regions, setRegions] = useState<TreeData>([])
  const timeRef = useRef<HTMLDivElement>(null)
  const searchSelectRef = useRef<SearchSelectRef>(null)

  const path = history.location.pathname
  const [activeKey, setActiveKey] = useState<string>()
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

  const searchParams = useDataCenterSearchParams()

  const currentPath = useMemo(() => {
    return {
      isDataCenter: path.includes(pathMap.dataCenter.url),
      isSubwayCenter: path.includes(pathMap.subwayCenter.url),
      isPatrolCenter: path.includes(pathMap.patrolCenter.url),
      isManageCenter: path.includes(pathMap.manageCenter.url),
    }
  }, [path])

  const showSearchSelect = useMemo(() => {
    return (
      dataCenterRs.keyAreaDistribute &&
      curArea.level >= districtLevelMap.city &&
      currentPath.isDataCenter
    )
  }, [dataCenterRs, curArea, currentPath])

  const isSubwayOrPatrol = currentPath.isSubwayCenter || currentPath.isPatrolCenter
  const isDataCenter = currentPath.isDataCenter
  const isBigScreenCenter = isDataCenter || isSubwayOrPatrol

  const { dataCenterTheme, subjectColor, mapBackgroundColor } = useTheme()

  const searchStr = useMemo(() => {
    return encodeURIComponent(
      btoa(
        qs.stringify({
          entityId: curEntity.entityId,
          adCode: curArea.adCode,
          businessType: curBiz.businessType,
        })
      )
    )
  }, [curEntity.entityId, curArea.adCode, curBiz.businessType])

  useEffect(() => {
    if (!showSearchSelect) {
      form.setFieldsValue({ fenceId: undefined })
      dispatch(currentFence({ fenceId: undefined }))
    }
  }, [showSearchSelect])

  useEffect(() => {
    // 地图下钻 同步行政范围筛选器
    if (curArea.adCode) {
      form.setFieldsValue({ regionId: curArea.adCode })
      const pIds = getAllParentIds(regions, [curArea.adCode])
      setExpandedKeys(pIds)
    }
  }, [curArea.adCode, regions])

  useEffect(() => {
    if (isBigScreenCenter) {
      // activeKey只在大屏有效，跳转到管理中心不改变当前activeKey
      setActiveKey(path)
    }
  }, [path])

  useEffect(() => {
    if (timeRef) {
      const timerId = setInterval(() => {
        timeRef.current && (timeRef.current.innerHTML = currentTime())
      }, 1000 * 58)
      return () => clearInterval(timerId)
    }
  }, [timeRef])

  useEffect(() => {
    if (curEntity.entityId) {
      queryEntityRegion()
    }
  }, [curEntity, userInfo])

  useEffect(() => {
    if (curEntity.entityId && curArea.adCode && curBiz.businessType) {
      if (currentPath.isDataCenter) {
        setTheme(subjectColor, mapBackgroundColor)
        history.push(`${pathMap.dataCenter.url}?${searchStr}`)
      } else if (currentPath.isPatrolCenter) {
        setTheme(defaultSubjectColor, defaultMapBackgroundColor)
        history.push(`${pathMap.patrolCenter.url}?${searchStr}`)
      } else {
        setTheme(defaultSubjectColor, defaultMapBackgroundColor)
      }
    }
  }, [curEntity, curArea, curBiz, currentPath])

  const queryEntityRegion = () => {
    const data = {
      entityId: curEntity.entityId,
    }
    request('commonQueryUserRegion', { data }).then(res => {
      if (res.code === 0 && res.data) {
        const regions = transformArea(res.data, node => ({
          isLeaf: !node.hasChildren,
          parentId: node.parentAdCode,
        }))
        setRegions(regions)
        addFirstChildLatLng(regions)
        // 验证searchParams.adCode有效性
        const searchRegion = findTreeNode(regions, 'adCode', searchParams.adCode)
        if (curEntity.fromSearch && searchRegion) {
          form.setFieldsValue({ regionId: searchParams.adCode })
          updateCurrentArea(searchRegion as AreaData)
        } else {
          form.setFieldsValue({ regionId: regions[0]?.value })
          updateCurrentArea(regions[0] as AreaData)
        }
      }
    })
  }

  const handleManageCenterClick = () => {
    if (currentPath.isManageCenter) {
      return
    }
    history.push(pathMap.manageCenter.url)
  }

  const handleDataCenterClick = () => {
    if (currentPath.isDataCenter) {
      return
    }
    history.push(`${pathMap.dataCenter.url}?${searchStr}`)
  }

  const handleLogoutClick = () => {
    dispatch({ type: APP.LOG_OUT })
  }

  const handleBizChange = (businessType: BusinessType) => {
    dispatch(currentBiz({ businessType }))
  }

  const updateCurrentArea = (area: AreaData) => {
    dispatch(currentArea(area.adCode === '1' ? ({ ...area, adCode: '1' } as any) : area))
  }

  const items = [{ label: <span onClick={handleLogoutClick}>退出登录</span>, key: '1' }]

  const menuItems: any = useMemo(() => {
    return [
      {
        key: pathMap.dataCenter.url,
        rs: true,
        label: <Link to={`${pathMap.dataCenter.url}?${searchStr}`}>{pathMap.dataCenter.name}</Link>,
      },
      {
        key: pathMap.subwayCenter.url,
        rs: subwayCenterRs.index,
        label: <Link to={pathMap.subwayCenter.url}>{pathMap.subwayCenter.name}</Link>,
      },
    ]
      .filter(item => !!item.rs)
      .map(({ rs, ...rest }) => rest)
  }, [subwayCenterRs, patrolCenterRs, searchStr])

  return (
    <div
      className={`header relative betweenCenter ${isBigScreenCenter ? 'dataCenterHeader' : ''}`}
      style={{
        backgroundImage: isSubwayOrPatrol
          ? `url(${titleBarImg})`
          : dataCenterTheme?.css['--theme-header-img'] && isDataCenter
          ? dataCenterTheme?.css['--theme-header-img']
          : '',
      }}
    >
      <div className="startCenter">
        <div className="startCenter absCenter">
          {app.entityIcon && (
            <div className="header-icon mr10">
              <img src={app.entityIcon} className="header-icon-img"></img>
            </div>
          )}
          <div className="header-title mr10">
            {curEntity?.entityName?.length > 20 ? (
              <Tooltip title={curEntity?.entityName} placement="right">
                {curEntity.entityName.slice(0, 20) + '...'}
              </Tooltip>
            ) : (
              curEntity.entityName || ''
            )}
          </div>
        </div>
        <Form layout="inline" form={form} className="gray07 header-from">
          <AdminEntitySelect
            key={'entityId'}
            formItemProps={{
              name: 'entityId',
              style: { display: currentPath.isPatrolCenter ? 'none' : '' },
            }}
            childProps={{
              dropdownMatchSelectWidth: false,
              allowClear: false,
              style: { width: isMobile() && currentPath.isDataCenter ? 90 : 150 },
              dropdownStyle: { maxHeight: 400, overflow: 'auto' },
              className: classnames({
                'custom-select': isBigScreenCenter,
                'custom-select-header': isBigScreenCenter,
                'subway-custom-select': isSubwayOrPatrol,
              }),
              popupClassName: isBigScreenCenter ? 'custom-select-dropdown' : '',
              // @ts-ignore
              onChange(id, entity: Entity) {
                lsSetItem('__entityId__', id)
                // 判断searchParams.businessType是否有效
                const searchBizType = +(searchParams.businessType as string)
                const isValidBiz =
                  entity.entityBusinessType === businessLineMap.double
                    ? searchBizType <= entity.entityBusinessType
                    : searchBizType === entity.entityBusinessType
                if (entity.fromSearch && isValidBiz) {
                  form.setFieldsValue({ bizType: searchBizType })
                  handleBizChange(searchBizType as any)
                } else {
                  form.setFieldsValue({ bizType: selectBizFromBizType(entity.entityBusinessType) })
                  handleBizChange(entity.entityBusinessType)
                }
                if (showSearchSelect) {
                  searchSelectRef.current?.setOptions([])
                  form.setFieldValue('fenceId', undefined)
                  dispatch(currentFence({ fenceId: undefined, selectFenceId: undefined }))
                }
              },
            }}
            extraProps={{ form }}
          ></AdminEntitySelect>
          <AreaTreeSelect
            formItemProps={{ name: 'regionId' }}
            childProps={{
              treeExpandedKeys: expandedKeys,
              allowClear: false,
              treeData: regions,
              style: { width: isMobile() ? 90 : 150, display: isBigScreenCenter ? '' : 'none' },
              className: classnames({
                'custom-tree-select': isBigScreenCenter,
                'subway-custom-select': isSubwayOrPatrol,
                'custom-tree-select-header': isBigScreenCenter,
              }),
              popupClassName: classnames({
                'custom-treeSelect-dropdown': isBigScreenCenter,
                'subway-custom-border': isSubwayOrPatrol,
              }),
              onTreeExpand(expandedKeys) {
                setExpandedKeys(expandedKeys)
              },
              onSelect(adCode, area) {
                updateCurrentArea(area as AreaData)
                if (showSearchSelect) {
                  searchSelectRef.current?.setOptions([])
                  form.setFieldValue('fenceId', undefined)
                  dispatch(currentFence({ fenceId: undefined, selectFenceId: undefined }))
                }
              },
            }}
          ></AreaTreeSelect>
          {showSearchSelect && (
            <div className="relative">
              <img
                className="absolute"
                style={{ width: 16, top: 7, left: 6 }}
                src={layerControlIcon.bike_search}
              ></img>
              <SearchSelect
                ref={searchSelectRef}
                formItemProps={{ name: 'fenceId' }}
                childProps={{
                  placeholder: '请输入重点点位搜索',
                  allowClear: true,
                  showArrow: false,
                  style: { width: isMobile() ? 108 : 178, color: '#fff' },
                  className: classnames(
                    'custom-select custom-select-header patrol-center-fence-search',
                    {
                      'subway-custom-select': isSubwayOrPatrol,
                    }
                  ),
                  popupClassName: classnames(
                    'custom-select-dropdown patrol-center-fence-search-dropdown',
                    { 'subway-custom-border': isSubwayOrPatrol }
                  ),
                  clearIcon: (
                    <CloseCircleOutlined
                      className="header-search-select-icon"
                      // style={{
                      //   color: '#fff',
                      //   backgroundColor: isDataCenter ? dataCenterTheme?.headerBgColor : '#1B2333',
                      // }}
                    />
                  ),
                  onClear() {
                    dispatch(currentFence({ fenceId: undefined }))
                  },
                  onSelect(value) {
                    dispatch(currentFence({ fenceId: value }))
                  },
                }}
                extraProps={{
                  url: 'patrolCenterFenceRemoteSearch',
                  data: { entityId: app.currentEntity.entityId, adCode: app.currentArea.adCode },
                  searchPropName: 'input',
                  formatData: data => {
                    const { result = [], total } = data
                    return { options: result, total }
                  },
                }}
              ></SearchSelect>
            </div>
          )}
          <BizSelect
            formItemProps={{ name: 'bizType' }}
            childProps={{
              allowClear: false,
              style: { width: 85, display: isBigScreenCenter ? '' : 'none' },
              className: classnames({
                'custom-select': isBigScreenCenter,
                'subway-custom-select': isSubwayOrPatrol,
                'custom-select-header': isBigScreenCenter,
              }),
              popupClassName: classnames({
                'custom-select-dropdown': isBigScreenCenter,
                'subway-custom-border': isSubwayOrPatrol,
              }),
              onChange(biz) {
                handleBizChange(biz)
              },
            }}
          ></BizSelect>
        </Form>
      </div>
      <div className="header-right centerCenter">
        {isMobile() && currentPath.isDataCenter ? null : (
          <div className="mr16" ref={timeRef}>
            {currentTime()}
          </div>
        )}
        {isMobile() && currentPath.isDataCenter ? null : (
          <div className="header-divider mr16"></div>
        )}
        {subwayCenterRs.index || patrolCenterRs.index ? (
          <Dropdown menu={{ items: menuItems }}>
            <span onClick={e => e.preventDefault()}>
              <span style={{ marginRight: 8 }}>
                {currentPath.isSubwayCenter && (
                  <img
                    src={dataIcon}
                    alt=""
                    className="mr5"
                    style={{ marginTop: '-2px', width: '12px', height: '12px' }}
                  />
                )}
                {activeKey
                  ? pathMap[activeKey.replace('/', '') as keyof typeof pathMap]?.name
                  : pathMap.dataCenter.name}
              </span>
              <DownOutlined
                style={{
                  color: isSubwayOrPatrol
                    ? '#296472'
                    : currentPath.isDataCenter
                    ? dataCenterTheme?.css['--theme-header-downoutlined-color']
                    : '',
                  fontSize: 12,
                }}
              />
            </span>
          </Dropdown>
        ) : (
          <div
            className={`pointer ${
              currentPath.isDataCenter
                ? 'header-menu-selected header-menu-selected-data-center'
                : ''
            }`}
            onClick={handleDataCenterClick}
          >
            {pathMap.dataCenter.name}
          </div>
        )}
        <div className="header-divider mr16 ml16"></div>
        {manageCenterRs.index && (
          <>
            <div
              className={`mr16 pointer ${currentPath.isManageCenter ? 'header-menu-selected' : ''}`}
              onClick={handleManageCenterClick}
            >
              {isSubwayOrPatrol && (
                <img
                  src={hierarchyFillIcon}
                  alt=""
                  className="mr5"
                  style={{ marginTop: '-2px', width: '12px', height: '12px' }}
                />
              )}
              {pathMap.manageCenter.name}
            </div>
            <div className="header-divider mr16"></div>
          </>
        )}
        <Dropdown menu={{ items }}>
          <span onClick={e => e.preventDefault()}>
            <span style={{ marginRight: 8 }}>{userInfo?.userName}</span>
            <DownOutlined
              style={{
                color: isSubwayOrPatrol
                  ? '#296472'
                  : currentPath.isDataCenter
                  ? dataCenterTheme?.css['--theme-header-downoutlined-color']
                  : '',
                fontSize: 12,
              }}
            />
          </span>
        </Dropdown>
      </div>
    </div>
  )
}

export default Header
