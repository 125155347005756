import React, { Suspense, useMemo, useEffect } from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Skeleton, notification } from 'antd'

import getRoutes from '@/routes'
import { useUserResources, useAppSelector } from '@/utils/hooks'
import { isMobile } from '@/utils/util'

import './App.less'

const App: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.app)
  const userResources = useUserResources(resources => resources)

  const routes = useMemo(() => {
    return getRoutes(userResources, userInfo)
  }, [userResources, userInfo])

  useEffect(() => {
    if (isMobile()) {
      notification.destroy()
      notification.info({
        message: '温馨提示',
        description: '移动设备建议横屏使用，效果更好！',
        duration: 6,
        placement: 'bottomRight',
      })
    }
  }, [])

  return (
    <Router>
      <Suspense fallback={<Skeleton active></Skeleton>}>
        <Switch>
          <Redirect exact from="/" to="/dataCenter" />
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              render={props => <route.component {...props} routes={route.routes} />}
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
